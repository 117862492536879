/*
 * 업무구분: 고객
 * 화 면 명: MSPCM006D
 * 화면설명: 카카오인증
 * 접근권한: 일반사용자, 지점장, 지역단스텝 (U000, F110, F102)
 * 작 성 일: 2022.06.09
 * 작 성 자: 이태훈
 * 2차 작성자: sh.park2022
 */
<template>
  <ur-page-container :show-title="true" title="카카오 인증" type="subpage" class="msp" @on-header-left-click="fn_Back">
    <ur-box-container direction="column" alignV="start">
      <div class="ns-insideWidthAuto full">
        <mo-validate-watcher ref="vWatcher">
          <ur-box-container direction="column" alignV="start" componentid="ur_box_container_000" class="ns-verify">
            <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="verify-box">
              <!-- 고객명 입력 -->
              <mo-text-field ref="custNmRef" v-model="custCardVO.custNm" :rules="validateRuleName" underline placeholder="고객명 입력 후 조회를 선택해주세요" :disabled="disabled_custNm" 
                maxlength="30" @keyup.enter="fn_CheckCustNm('enter')" @blur="fn_CheckCustNm"/>
              <!-- 고객명 조회 버튼 -->
              <mo-button class="ns-btn-round" @click="fn_CheckCustNm('enter')" :disabled="disabled_custNm">조회하기</mo-button>
                
              <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-check">
                <!-- 인증결과-->
                <mo-checkbox v-if="custCardVO.isExistCustYN === 'Y'" class="is-checked" readonly>기존고객입니다.</mo-checkbox>
                <mo-checkbox v-if="custCardVO.isExistCustYN === 'N'" class="is-checked" readonly>신규고객입니다.</mo-checkbox>
                <!-- 외국인 체크 박스 -->
                <!-- 고객조회 완료 시, 외국인 선택 못하도록 처리 : 2022-08-12 -->
                <!-- <mo-checkbox v-model="fn_IsForeigner" :disabled="(infoPrcusCnsntTypCd==='19')? true: (disabled_custNm === true)? true: false">외국인</mo-checkbox> -->
                <mo-checkbox v-model="fn_IsForeigner" :disabled="disabled_exist_foreign">외국인</mo-checkbox>
              </ur-box-container>
            </ur-box-container>

            <!-- 생년월일 -->
            <ur-box-container alignV="start" componentid="ur_box_container_003" direction="column">
              <div class="verify-birth">
                <!-- 내국인 -->
                <mo-text-field ref="knbFrnoRef1" v-if="!isForeigner" :rules="validateRuleKnbFrno" type="number" mask="######" v-model="fn_KnbFrno" underline class="birth-input1" 
                  placeholder="생년월일" :disabled="disabled_exist"/>
                <!-- 외국인-->
                <mo-text-field ref="knbFrnoRef2" v-else :rules="validateRuleKnbFrno" type="number" mask="######" v-model="fn_KnbFrno" underline class="birth-input1" placeholder="외국인등록번호" 
                  :disabled="disabled_exist"/>

                <span>-</span>
                <mo-text-field ref="knbBknoRef" :rules="validateRuleKnbBkno" type="number" mask="#" v-model="fn_Gender" underline class="birth-input2" placeholder="0" 
                  :disabled="disabled_exist"/>
                <em>******</em>
              </div>
            </ur-box-container>

            <!-- 전화번호 -->
            <ur-box-container alignV="start" componentid="ur_box_container_004" direction="column">
              <div class="verify-phone">
                <!-- 전화번호 앞자리 -->
                <msp-bottom-select :items="phoneItems" v-model="fn_CelnoFrno" placeholder="선택" underline :disabled="disabled_inComp"/>
                <!-- 전화번호 뒷자리 -->
                <mo-text-field ref="celnoRef" v-model="fn_CelnoBkno" :rules="validateRuleCelno" underline placeholder="00000000" :readonly="disabledObj.celnoBknoDisabled"
                  :disabled="disabled_inComp" type="number" mask="########"/>
              </div>
            </ur-box-container>

            <!-- 인증전송 -->
            <ur-box-container alignV="start" componentid="ur_box_container_006" direction="column" class="verify-btn">
              <mo-button class="ns-btn-round" @click="fn_BtnP1OnClick" :disabled="disabled_sendBtn">카카오 인증 전송</mo-button>
              <mo-button class="ns-btn-round" @click="fn_BtnP2OnClick" :disabled="disabled_resBtn">인증결과 확인</mo-button>
              <span class="txt orange" v-if="!isSuccess">{{resAthntStatRespCdVal}}<br>{{resAthntStatRespCdVal2}}</span>

              <span class="txt gray">
                카카오 인증 결과에 대한 자세한 확인은 카카오 및<br>카카오 고객센터로 문의
              </span>

              <span class="txt blue"><em>&#9742;</em>1644-7405</span>
            </ur-box-container>

            <!-- MSPCM007P 내용 이곳에서 사용 -->
            <!-- 개인정보 이용 및 활용 동의 팝업 -->
            <mo-bottom-sheet ref="bottomSheet007P" class="ns-bottom-sheet ns-main-sheets" @before-close="onBeforeClose">
              <template v-slot:title>개인정보 이용 및 활용 동의</template>
              <div class="verify-agree">
                <p>
                  (주)카카오는 본인인증서비스 제공을 위해 본인으로부터 아래의 개인정보를 입력받으며, 
                  입력 받은 개인정보의 수집 및 이용을 위해 다음의 사항에 대하여 본인으로부터 동의를 받습니다.
                  <br><br>
                  개인(신용)정보 제 3자 제공동의<br>
                  -제공목적: 본인인증<br>
                  -제공받는자: (주)카카오<br>
                  -제공받는자의 개인정보 보유 이용기간: 목적 달성 후 즉시파기<br>
                  -제공항목: 이름, 생년월일, 휴대폰번호
                </p>
              </div>
              <template v-slot:action>
                <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative">
                  <div class="relative-div">
                    <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_CloseAgreePop('btnAgree')">확인</mo-button>
                  </div>
                </ur-box-container>
              </template>
            </mo-bottom-sheet>

            <!-- 카카오 인증 결과 안내 bottomSheet -->
            <mo-bottom-sheet ref="kakaoAuthPop" class="ns-bottom-sheet ns-style3">
              <div>
              <p class="cancel-ment" v-html="modalMsg"></p>
              </div>
              <template v-slot:action>
                <ur-box-container alignV="start" componentid="ur_box_container_011" direction="column" class="ns-btn-relative">
                  <div class="relative-div">
                    <mo-button componentid="mo_button_005" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_KakaoAuthPop('Confirm')">확인</mo-button>
                  </div>
                </ur-box-container>
              </template>
            </mo-bottom-sheet>

            <!-- modal창 -->
            <mo-modal ref="modal" title="Title" no-header>
              <template>
                <span v-html="modalMsg"></span>
              </template>
              <template slot="action">
                <mo-button @click="fn_CloseModal" main>확인</mo-button>
              </template>
            </mo-modal>

            <div class="ns-height70"></div>

          </ur-box-container>
        </mo-validate-watcher>
      </div>
    </ur-box-container>
  </ur-page-container>
</template>

<script>

/************************************************************************************************
* 공통 라이브러리 INCLUDE 영역
************************************************************************************************/
import Msg from '@/systems/webkit/msg/msg'
import cmConstants from '@/config/constants/cmConstants'
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
import MspBottomSelect from '@/ui/_cmm/components/MspBottomSelect.vue' /* dropdown bottom-sheet */

import MSPCM005P from '@/ui/cm/MSPCM005P' // 고객선택

export default {
  /************************************************************************************************
  * Vue 파일 속성 정의 영역
  ************************************************************************************************/
  // 현재 화면명
  name: 'MSPCM006D',
  // 현재 화면 ID
  screenId: 'MSPCM006D',
  // 컴포넌트 선언
  components: {
    MSPCM005P,
    MspBottomSelect
  },
  props: {
  },
  /************************************************************************************************
  * 화면 전역 변수 선언 영역
  ************************************************************************************************/
  data () {
    return {
        stndKeyList: [],
        validateRuleName: [
          v => !!v || '필수 입력항목입니다',
          v => this.custNmResult || this.custNmErrorMsg
        ],
        validateRuleKnbBkno: [
          v => !!v || '필수 입력항목입니다',
          v => this.fn_CheckCKnbGender() || this.knbErrorMsg
        ],
        validateRuleKnbFrno: [
          v => !!v || '필수 입력항목입니다',
          v => v.length > 5 || '생년월일을 입력하세요.'
        ],
        validateRuleCelno: [
          v => !!v || '필수 입력항목입니다',
          v => v.length > 7 || '휴대폰번호를 입력하세요.',
          v => this.chkCelnoResult || this.celnoErrorMsg
        ],
      popup005: {}, // MSPCM005P 고객선택 팝업

      lv_Cds_ZA_ELTRN_MAL_DM_CD: [], // 이메일도메인 코드정보
      phoneItems: [], // 휴대폰 앞자리
      
      // 주고객 정보 VO
      custCardVO: {
        custNm: '', // 이름
        chnlCustId: '', // 채널고객ID
        chnlCstgrId: '', // 채널그룹ID
        knb: '', // 전체주민번호
        knbScCd: 'ZPER01', // 식별번호구분코드
        knbBackup: '', // 주민번호 백업 - TSSSP103M의 이전 버튼 선택 시, 마지막 등록된 주민번호값을 화면에 display해야 함.
        knbFrno: '', // 주민번호 앞자리
        knbBkno: '', // 주민번호 뒷자리
        knbBkFstNo: '', // 주민번호 뒷자리 첫자리 (성별구분코드)
        knbBknoCipher: '', // 암호화된 주민번호
        knbBknoCipherBackup: '', // 주민번호 뒷자리 백업(암호화 값이 들어올 경우에만 임시 보관장소로 쓰임)
        celno: '', // 휴대전화 전체
        celnoFrno: '', // 휴대전화 앞자리
        celnoBkno: '', // 휴대전화 뒷자리
        isExistCustYN: ''
      },

      //=====================
      // 인증 관련 변수
      //=====================
      agreeCheckbox: false, // 본인확인 서비스 이용동의
      isSuccess: false, // 인증성공여부
      modalMsg: '', // 카카오 인증 에러 모달 메세지
      sendAthntReqRespCdVal: '', // 카카오톡인증상태응답값
      resAthntStatRespCdVal: '', // 카카오톡인증상태응답코드값
      resAthntStatRespCdVal2: '', // 카카오톡인증상태응답코드값2
      athntTrnstId: '', // 인증트랜잭션ID
      athntHstrId: '', // 인증이력ID
      sCertVnoUrl: 'https://cert.vno.co.kr/app/agree/agree_main.jsp', // 이통사 본인확인 서비스 이용에 따른 동의 나이스 링크 URL

      //=====================
      // 버튼 활성화 여부 변수
      //=====================
      disabled_sendBtn: true, // 카카오 인증 전송 버튼 - 초기진입 시 : 비활성화
      disabled_resBtn: true, // 인증결과 확인 버튼 - 초기진입 시 : 비활성화
      disabled_inComp: true, // 휴대폰번호 입력 컴포넌트 - 초기진입 시 : 비활성화
      disabled_exist: true, // 생년월일, 외국인 입력 컴포넌트 - 초기진입 시 : 비활성화
      disabled_exist_foreign: false,
      disabled_custNm: false, // 고객명, 조회 입력 컴포넌트 - 초기진입 시 : 활성화

      //================
      // 유효성 체크 변수
      //================
      chkCelnoResult: true, // 핸드폰번호 유효성
      celnoErrorMsg: '', // 핸드폰번호 에러 메시지
      chkKnbResult: true, // 주민번호 유효성 검증 결과
      knbErrorMsg: '', // 주민번호 유효성 체크 후 에러 메시지
      custNmResult: true, // 이름 유효성 체크 결과
      custNmErrorMsg: '', // 이름 유효성 결과 에러 메시지 '유효하지 않은 고객명입니다. 정확한 정보를 확인하시기 바랍니다.(숫자,영문, 특수문자 등)'
      isForeigner: false, // 외국인 체크 여부
      chkRrn: false, // 미확보 체크여부
      
      showPopup: false,
      isClickSendNo: false,
      isSendNo: false,
      
      number: '',
      disabledObj: {
        chkForeignerDisabled: false,
        mcnstDisabled: false,
        mktDisabled: false,
        knbFrnoDisabled: false,
        knbBknoDisabled: false,
        addrTypeDisabled: false,
        zipBtnDisabled: false,
        entryDisabled: false,
        celnoFrnoDisabled: false,
        celnoBknoDisabled: false,
        emailDisabled: false,
        emailBknoDisabled: false,
        jobDisabled: false,
        nextBtnDisabled: false // 다음버튼 비활성화 여부
      },
      
      lv_cnsltNo: '', // 컨설턴트 번호
      agreeTypeMcnst: false, // 필수컨설팅 동의 여부
      mktChkList: [], //마컨 정보(광고성 수신동의)['P', 'S', 'M', 'E']
      mktCnsntAvlPrd : '', //개인(신용)정보 보유이용기간 선택
      infoPrcusCnsntTypCd: '', // 정보활용동의 유형코드(17: 필수컨설팅, 19: 마케팅동의, 18: 둘다)
      segmentType1: '', //마케팅 (거래, 미거래)

      routeCustNm: ''
    }
  },

  /************************************************************************************************
  * Life Cycle 함수 정의 영역
  ************************************************************************************************/
  /** computed 함수 정의 영역 */
  computed: {
    modal () {
      return this.$refs.modal
    },
    fn_KnbFrno: { // 주민번호 앞자리 변경시 호출 - 전체주민번호변수(custCardVO.knb)에 변경 값 처리
      get: function () {
        return this.custCardVO.knbFrno
      },
      set: function (data) {
        this.custCardVO.knbFrno = data
        if (data.length === 6) {
          this.fn_NextComp ('knbBknoRef')
        }
      }
    },
    fn_Gender: { // 주민번호 뒷자리 성별정보 입력 시 처리
      get: function () {
        return this.custCardVO.knbBkFstNo
      },
      set: function (data) {
        this.custCardVO.knbBkFstNo = data

        if (data.length === 1) {
           this.fn_NextComp ('celnoRef')
        }
      }
    },
    fn_IsForeigner: { // 외국인 체크박스
      get: function () {
        return this.isForeigner
      },
      set: function (data) {
        this.isForeigner = data

        if (data) {
          this.custCardVO.knbScCd = 'ZPER02' // 외국인
          this.$refs.vWatcher.clearValidation()
        } else {
          this.custCardVO.knbScCd = 'ZPER01' // 내국인
        }
      }
    },
    fn_CelnoFrno: { // 핸드폰번호 앞자리 변경시 호출 - 전체핸드폰번호변수(custCardVO.celno)에 변경 값 처리
      get: function () {
        return this.custCardVO.celnoFrno
      },
      set: function (data) {
        if (this.custCardVO.celnoFrno !== null && this.custCardVO.celnoFrno !== data) {
          this.custCardVO.isCelnoChanged = true
        }

        this.custCardVO.celnoFrno = data // 휴대전화 앞자리
        this.custCardVO.celno = data + this.custCardVO.celnoBkno // 휴대전화 앞자리 + 휴대전화 뒷자리
      }
    },
    fn_CelnoBkno: { // 핸드폰번호 뒷자리 변경시 호출 - 전체핸드폰번호변수(custCardVO.celno)에 변경 값 처리
      get: function () {
        return this.custCardVO.celnoBkno
      },
      set: function (data) {
        if (this.custCardVO.celnoBkno !== '' && this.custCardVO.celnoBkno.replace('-', '').replace('-', '') !== data) {
          this.custCardVO.isCelnoChanged = true
        }
        this.custCardVO.celnoBkno = data
        this.custCardVO.celno = this.custCardVO.celnoFrno + data
      }
    },

  },
  /** watch 정의 영역 */
  watch: {},

  /** 설명 : Step-2 인스턴스가 작성된 후 동기적 호출 */
  created () {

    // 사용자 정보
    this.userInfo = this.getStore('userInfo').getters.getUserInfo
    this.lv_cnsltNo = this.userInfo.userId

    // 로딩 시 정보항목 초기화 및 설정
    this.fn_Init()

    if(!_.isEmpty(this.$route.params)){
      this.agreeTypeMcnst = this.$route.params.agreeTypeMcnst //필컨 정보 여부
      this.mktChkList = this.$route.params.mktChkList //마컨 정보(광고성 수신동의)
      this.routeCustNm = !this.$bizUtil.isEmpty(this.$route.params.custNm) ? this.$route.params.custNm : ''
      this.custCardVO.custNm = this.routeCustNm
      this.segmentType1 = this.$route.params.segmentType1 
      this.mktCnsntAvlPrd = this.$route.params.mktCnsntAvlPrd //마컨 정보(개인정보 보유이용기간)
      this.infoPrcusCnsntTypCd =  this.$route.params.infoPrcusCnsntTypCd //필컨만 : 17, 마동만 : 19, 필컨&마동 : 18
    } else {
      let custInfoStore = window.vue.getStore('cmStore').getters.getState.custInfo

      this.infoPrcusCnsntTypCd = custInfoStore.infoPrcusCnsntTypCd
      this.pCustNm = !this.$bizUtil.isEmpty(custInfoStore.custNm) ? custInfoStore.custNm : ''
    }
     this.$BottomManager.fn_SetBottomVisible(false)
     // backButton event 등록
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_Back)
  },

  /** 설명 : Step-4 마운트 된 후 호출 */
  mounted () {

    // 개인정보 이용 및 활용 동의 팝업 open
    // 최초 접근 시 1회만 실행
    if (!this.agreeCheckbox) {
      this.$refs.bottomSheet007P.open()
    }

    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog('MSPCM006D')
  },
  
  /** 설명 : Step-7 인스턴스가 Remove 되기 전 호출 */
  beforeDestroy () {
    // this.$BottomManager.fn_SetBottomVisible(true)
    // backButton event 해제
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_Back)

  },
  /************************************************************************************************
  * 사용자 함수 정의 영역
  ************************************************************************************************/
  methods: {
    /******************************************************************************
     * Function명 : onBeforeClose
     * 설명       : 개인정보 이용 및 활용 동의 확인버튼을 제외하곤 닫지 못하도록 설정
     *              확인 버튼 누를 시 item 값 : btnAgree
     *              다른 영역 누를 시 item 값 : swipe
     ******************************************************************************/
    onBeforeClose (fn, item) {
      if (item === 'swipe') return
      fn() // 닫기 실행
    },

    /******************************************************************************
     * Function명 : fn_CloseAgreePop
     * 설명       : 개인정보 이용 및 활용 동의 확인 버튼
     ******************************************************************************/
    fn_CloseAgreePop (v) {
      this.agreeCheckbox = true // 개인정보 이용 및 활용 동의 확인으로 변경
      this.$BottomManager.fn_SetBottomVisible(false)

      // 개인정보 이용 및 활용 동의 팝업 close
      this.$refs.bottomSheet007P.close(v)
        this.$nextTick(() => {
        this.$refs['custNmRef'].focus()
      })
    },

    /******************************************************************************
     * Function명 : fn_Init
     * 설명       : 로딩시 정보항목 초기화 및 설정
     ******************************************************************************/
    fn_Init () {
      const lv_Vm = this

      // 휴대전화 앞자리 정보 세팅
      // let lv_CelphCD = lv_Vm.getStore('code').getters.getState.get('ZA_CELPH_KNB_CD')
      let defaultValu = {value: '0', text: '선택'}
      let lv_CelphCD = this.$bizUtil.cloneDeep(cmConstants.CELPH_KNB_CD)
      this.phoneItems = [defaultValu, ...lv_CelphCD]

      // 휴대폰번호 앞자리 초기값 셋팅
      lv_Vm.custCardVO.celnoFrno = '010'
    },

    /******************************************************************************
    * Function명 : fn_CheckCustNm
    * 설명       : 신규일 때 고객명 입력 후 focus out 될 때 호출
    ******************************************************************************/
    fn_CheckCustNm (param) { // 고객명 체크
      this.$refs.vWatcher.clearValidation()
      if (this.$bizUtil.isEmpty(this.custCardVO.custNm)) {
        this.$refs['custNmRef'].validate()
        return
      }
      let lv_CustNmResult = this.custCardVO.custNm.trim()
      let regexp_eng = '[a-zA-Z]' // 영문(대소)
      
      this.custCardVO.custNm = lv_CustNmResult

      if (this.$bizUtil.isEmpty(lv_CustNmResult)) {
        this.custNmResult = false // 이름 유효성 체크 결과
        this.custNmErrorMsg = '필수 입력항목입니다'        
        this.$refs.vWatcher.validate()
        return
      }
      
      // 한글 10글자, 영문은 30글자 제한
      if (!this.$bizUtil.isEmpty(lv_CustNmResult)) {
        if (lv_CustNmResult.search(regexp_eng) > -1) { // 영문이 있으면
          lv_CustNmResult = lv_CustNmResult.substr(0, 30)
          this.custCardVO.custNm = lv_CustNmResult
        } else {
          lv_CustNmResult = lv_CustNmResult.substr(0, 10)
          this.custCardVO.custNm = lv_CustNmResult
        }
      }

      // 마케팅동의만 한 외국인인 경우 필수컨설팅 안내 문자 추가
      if (lv_CustNmResult.search(regexp_eng) > -1) {
        if (this.$bizUtil.isCustNameValidation(lv_CustNmResult, 'ZPER02')) {
          if (this.infoPrcusCnsntTypCd==='19') {
            this.custNmResult = false // 이름 유효성 체크 결과
            this.custNmErrorMsg = '외국인 고객님은 마케팅동의 단독처리가 불가합니다.'
            this.$refs.vWatcher.validate()
            return
          } else {
            if (!this.isForeigner) {
              this.custNmResult = false // 이름 유효성 체크 결과
              this.custNmErrorMsg = '외국인 체크를 해주세요'
              this.$refs.vWatcher.validate()
              return
            }
          }
        }
      }

      if (this.$bizUtil.isCustNameValidation(lv_CustNmResult, this.custCardVO.knbScCd)) {
        this.custNmResult = true // 이름 유효성 체크 결과
        // 기존고객 조회 서비스
        if (param === 'enter') { // 엔터키 입력시에만 고객조회함
          this.fn_SearchCustomerInfo()
          return
        }
      } else {
        this.custNmResult = false // 이름 유효성 체크 결과
        this.custNmErrorMsg = '유효하지 않은 고객명입니다. 정확한 정보를 확인하시기 바랍니다.(숫자,영문, 특수문자 등)'
      }

      if (this.$commonUtil.isMobile) { // 모바일일 경우만 다음버튼 활성화 되게 처리
        this.fn_IsNextBtnVisible(true)
      }

      this.$refs.vWatcher.validate()

      return this.custNmResult
    },

    /******************************************************************************
     * Function명 : fn_SearchCustomerInfo
     * 설명       : 기존고객 조회 (정보활용 동의 정보 조회 서비스 호출)
     ******************************************************************************/
    fn_SearchCustomerInfo () {
      this.isLoading = true
      let lv_Vm = this
      lv_Vm.custCardVO.isExistCustYN = '' // 기존/신규 여부 초기화

      const validOnlyEnglish = (s) => !/(?=.*[^\w\s])/.test(s)
      lv_Vm.lv_FilterSearchCustNm = lv_Vm.custCardVO.custNm
      if (validOnlyEnglish(lv_Vm.lv_FilterSearchCustNm)) {
        lv_Vm.custCardVO.custNm = lv_Vm.lv_FilterSearchCustNm.toUpperCase()
      }

      let pParams =
                  {
                    cnsltNo: this.getStore('userInfo').getters.getUserInfo.userEno, // 컨설턴트사번
                    custNm: lv_Vm.custCardVO.custNm.trim() // 고객명
                  }
      
      // GA 매니저 대행 컨설턴트 변경시
      if(this.$cmUtil.fn_GachangeTF()){
        pParams.cnsltNo = this.getStore('cmStore').getters.getState.gssEplyInfo.eplyNo
      }
      //매니저가 컨설턴트를 변경했을경우
      this.stndKeyList = []
      if(this.$cmUtil.fn_GachangeTF()){
        this.stndKeyList = [{stndKeyId: 'cnsltNo', stndKeyVal: this.getStore('cmStore').getters.getState.gssEplyInfo.eplyNo}]
      }

      let trnstId = 'txTSSCM10S4'

      this.post(lv_Vm, pParams, trnstId, 'S')
        .then(function (response) {
          // 필수 컨설팅 리스트
          if (response.body !== null) {
            if (response.body.cMInfoPrcusCnsntListVO !== null) {
              lv_Vm.items = response.body.cMInfoPrcusCnsntListVO
              if (lv_Vm.items.length === 0) {
                lv_Vm.getStore('confirm').dispatch('ADD', '신규고객 입니다.\n정보 입력 후 본인인증을 시도해주세요')
                lv_Vm.disabled_exist = false // 생년월일, 외국인 입력 컴포넌트 활성화
                lv_Vm.disabled_exist_foreign = false
                lv_Vm.disabled_inComp = false // 휴대폰번호 입력 컴포넌트 활성화
                lv_Vm.disabled_sendBtn = false // 카카오 인증 버튼 활성화
                lv_Vm.custCardVO.isExistCustYN = 'N'
                lv_Vm.custCardVO.chnlCustId = ''
                lv_Vm.custCardVO.chnlCstgrId = ''
                lv_Vm.custCardVO.knbFrno = ''
                lv_Vm.custCardVO.knbBkno = ''
                lv_Vm.custCardVO.knbBkFstNo = ''
                lv_Vm.custCardVO.celno = ''
                lv_Vm.custCardVO.celnoFrno = lv_Vm.phoneItems[1].value
                lv_Vm.custCardVO.celnoBkno = ''
              } else if (lv_Vm.items.length > 0) {
                // 고객 조회 팝업창 띄우기
                lv_Vm.popup005 = lv_Vm.$bottomModal.open(MSPCM005P, {
                  properties: {
                    pCustNm: lv_Vm.custCardVO.custNm // 고객명 파라미터
                  },
                  listeners: {
                    confirmPopup: (pData) => {
                      lv_Vm.$bottomModal.close(lv_Vm.popup005)

                      // 파라미터 세팅
                      lv_Vm.fn_ConfirmMSPCM005P(pData)
                      lv_Vm.$refs.vWatcher.clearValidation()
                      lv_Vm.disabled_sendBtn = false // 카카오 인증 버튼 활성화
                    }
                  }
                })
              }
            } else {
              lv_Vm.getStore('confirm').dispatch('ADD', '신규고객 입니다.\n정보 입력 후 본인인증을 시도해주세요')
              lv_Vm.disabled_exist = false // 생년월일, 외국인 입력 컴포넌트 활성화
              lv_Vm.disabled_exist_foreign = false
              lv_Vm.disabled_inComp = false // 휴대폰번호 입력 컴포넌트 활성화
              lv_Vm.disabled_sendBtn = false // 카카오 인증 버튼 활성화
              lv_Vm.custCardVO.isExistCustYN = 'N'
              lv_Vm.custCardVO.chnlCustId = ''
              lv_Vm.custCardVO.chnlCstgrId = ''
              lv_Vm.custCardVO.knbFrno = ''
              lv_Vm.custCardVO.knbBkno = ''
              lv_Vm.custCardVO.knbBkFstNo = ''
              lv_Vm.custCardVO.celno = ''
              lv_Vm.custCardVO.celnoFrno = lv_Vm.phoneItems[1].value
              lv_Vm.custCardVO.celnoBkno = ''
            }
          } else {
            lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
          }

          lv_Vm.isLoading = false
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },

    /******************************************************************************
    * Function명 : fn_ConfirmMSPCM005P
    * 설명       : 고객검색 팝업창에서 확인 버튼 클릭 시 호출.
                  - 팝업창 닫고, 팝업에서 넘어온 파라미터로 화면 셋팅
    ******************************************************************************/
    fn_ConfirmMSPCM005P (param) {
      if (param != null) {
        if (param.isExistCustYN === 'Y') {
          this.custCardVO.custNm = param.custNm
          this.custCardVO.isExistCustYN = param.isExistCustYN
          this.custCardVO.chnlCustId = param.chnlCustId
          this.custCardVO.chnlCustScCd = param.chnlCustScCd
          this.custCardVO.chnlCstgrId =  param.chnlCstgrId
          this.custCardVO.knbFrno = param.knb.substring(0, 6)
          this.custCardVO.knbBkFstNo = param.knb.substring(6, 7)

          if (this.custCardVO.knbBkFstNo === '5' || this.custCardVO.knbBkFstNo === '6' || this.custCardVO.knbBkFstNo === '7' || this.custCardVO.knbBkFstNo === '8') { // 외국인인 경우
            this.isForeigner = true
            this.custCardVO.knbScCd = 'ZPER02'
          } else if (this.custCardVO.knbBkFstNo === '1' || this.custCardVO.knbBkFstNo === '2' || this.custCardVO.knbBkFstNo === '3' || this.custCardVO.knbBkFstNo === '4') { // 내국인인 경우
            this.isForeigner = false
            this.custCardVO.knbScCd = 'ZPER01'
          }
          /************************************************************************************
          * ----------- 휴대전화 세팅 start -----------
          ************************************************************************************/
          if (param.celno !== undefined && param.celno.trim() !== '') {
            param.celnoOrg = param.celno.split('-')
            let lv_CelSplit = param.celno.split('-')
            let tmpCelnoFrno = lv_CelSplit[0] // 휴대전화 번호 앞자리

            // 조회한 휴대전화번호 앞자리의 값이 있는지 체크함. 없는 경우 tmpCelnoFrnoObj 빈 객체임.
            let tmpCelnoFrnoObj = this.phoneItems.filter(
              (value) => {
                if (value.key === tmpCelnoFrno) {
                  return value
                }
              }
            )

            // 휴대전화 번호 앞자리
            if (tmpCelnoFrnoObj.length > 0) {
              this.custCardVO.celnoFrno = tmpCelnoFrnoObj[0]
            } else {
              this.custCardVO.celnoFrno = tmpCelnoFrno
            }

            let lv_CelnoBkno = param.celno.substring(tmpCelnoFrno.length) // 휴대전화 번호 뒷자리
            this.custCardVO.celnoBkno = lv_CelnoBkno.replace(/\-/g, '') // lv_CelnoBkno.replace('-', '') // 첫번째 dash 제거
          }
          /************************************************************************************
           * ----------- 휴대전화 세팅 end -----------
           ************************************************************************************/
          this.disabled_exist = true // 생년월일, 외국인 입력 컴포넌트 비활성화
          this.disabled_exist_foreign = true
          this.disabled_inComp = false // 휴대폰번호 입력 컴포넌트 활성화
        } else {
          this.disabled_exist = false // 생년월일, 외국인 입력 컴포넌트 활성화
          this.disabled_exist_foreign = false
          this.disabled_inComp = false // 휴대폰번호 입력 컴포넌트 활성화
          this.custCardVO.isExistCustYN = param.isExistCustYN
          this.custCardVO.chnlCustId = ''
          this.custCardVO.chnlCstgrId = ''
          this.custCardVO.knbFrno = ''
          this.custCardVO.knbBkno = ''
          this.custCardVO.knbBkFstNo = ''
          this.custCardVO.celno = ''
          this.custCardVO.celnoFrno = this.phoneItems[1].value
          this.custCardVO.celnoBkno = ''
        }
      }
    },
    /******************************************************************************
     * Function명 : fn_CloseModal
     * 설명       : 모달 창 닫음
     ******************************************************************************/
    fn_CloseModal () {
      this.modal.close()
    },
    /******************************************************************************
     * Function명 : fn_BtnP1OnClick
     * 설명       : 카카오 인증 전송
     ******************************************************************************/
    fn_BtnP1OnClick () {

      if (!this.$refs.vWatcher.isValid()) {
        return
      }

      if (this.fn_Validation()) { // 추가 유효성 검사
        this.isLoading = true

        this.sendAthntReqRespCdVal = '' // 카카오톡인증상태응답값 초기화
        this.athntTrnstId = '' // 인증트랜잭션ID 초기화
        this.athntHstrId = '' // 인증이력ID 초기화
        // 인증결과값 초기화
        this.isSuccess = false // 인증성공여부
        this.resAthntStatRespCdVal = '' // 카카오톡인증상태응답코드값
        this.resAthntStatRespCdVal2 = '' // 카카오톡인증상태응답코드값2
        
        // 식별번호값 세팅
        let lv_knb = this.custCardVO.knbFrno + this.custCardVO.knbBkFstNo + '000000'

        let lv_Vm = this
        let pParams =
                      {
                        custNm: lv_Vm.custCardVO.custNm, // 고객명
                        chnlCustId: '', // 채널고객ID
                        chnlCstgrId: '', // 채널그룹ID
                        cnsltNo: lv_Vm.lv_cnsltNo, // 컨설턴트번호
                        knb: lv_knb, // 주민등록번호
                        // knbFrno: lv_Vm.custCardVO.knbFrno, // 주민번호 앞자리
                        // knbBknoCipher: lv_Vm.custCardVO.knbBknoCipher, // 주민번호 뒷자리(암호화)
                        infoPrcusCnsntTyoCd: lv_Vm.infoPrcusCnsntTypCd, // 정보활용동의유형코드(17:필수컨설팅동의, 19:마케팅동의, 18: 필컨/마동 둘다 선택, 11:전환설계)
                        celno: lv_Vm.custCardVO.celno // 전화번호
                      }

        // GA 매니저 대행 컨설턴트 변경시
        if(this.$cmUtil.fn_GachangeTF()){
          pParams.cnsltNo = this.getStore('cmStore').getters.getState.gssEplyInfo.eplyNo
        }
        //매니저가 컨설턴트를 변경했을경우
        this.stndKeyList = []
        if(this.$cmUtil.fn_GachangeTF()){
          this.stndKeyList = [{stndKeyId: 'cnsltNo', stndKeyVal: this.getStore('cmStore').getters.getState.gssEplyInfo.eplyNo}]
        }

        let trnstId = 'txTSSCM12S2'
        this.post(lv_Vm, pParams, trnstId, 'S')
          .then(function (response) {
            if (response.body !== null && response.body !== '') {
              if (response.body.athntReqRespCd === 'P000') {
                // 정상 처리인 경우 - 인증전송 버튼 비활성화 / 인증결과 확인 버튼 활성화 / 고객정보 입력 컴포넌트 비활성화
                lv_Vm.disabled_sendBtn = true // 카카오 인증 전송 버튼 비활성화
                lv_Vm.disabled_resBtn = false // 인증결과 확인 버튼 활성화
                lv_Vm.disabled_inComp = true // 정상처리인 경우 - 휴대폰 입력 컴포넌트 비활성화
                lv_Vm.disabled_exist = true // 생년월일 입력 컴포넌트 비활성화
                lv_Vm.disabled_exist_foreign = true
                lv_Vm.disabled_custNm = true // 고객명 입력 컴포넌트 비활성화
                // 인증트랜잭션ID
                lv_Vm.athntTrnstId = response.body.athntTrnstId
                lv_Vm.athntHstrId = response.body.athntHstrId
              } else {
                // 정상처리가 아닌경우 - 인증전송 버튼 활성화 / 인증결과 확인 버튼 비활성화
                lv_Vm.disabled_sendBtn = false // 카카오 인증 전송 버튼 활성화
                lv_Vm.disabled_resBtn = true // 인증결과 확인 버튼 비활성화

                if (response.body.athntReqRespCd === 'E400') {
                  lv_Vm.sendAthntReqRespCdVal = '(카카오톡 미사용/해지 고객)'
                } else if (response.body.athntReqRespCd === 'E401') {
                  lv_Vm.sendAthntReqRespCdVal = '(휴대폰 번호 누락/불일치)'
                } else if (response.body.athntReqRespCd === 'E402') {
                  lv_Vm.sendAthntReqRespCdVal = '(고객명 누락/불일치)'
                } else if (response.body.athntReqRespCd === 'E403') {
                  lv_Vm.sendAthntReqRespCdVal = '(생년월일 누락/불일치)'
                } else if (response.body.athntReqRespCd === 'E421') {
                  lv_Vm.sendAthntReqRespCdVal = '(생년월일 불일치)'
                } else if (response.body.athntReqRespCd === 'E422') {
                  lv_Vm.sendAthntReqRespCdVal = '(고객명 불일치)'
                } else {
                  lv_Vm.sendAthntReqRespCdVal = ''
                }

                // 모달 창 오픈
                lv_Vm.modalMsg = '카카오 인증이<br/> 발송되지 않았습니다.<br/>' + lv_Vm.sendAthntReqRespCdVal + '<br/><br/>자세한 사항에 대한 문의는<br/> 카카오 고객센터(1644-7405)로 <br/>문의바랍니다.'
                lv_Vm.fn_KakaoAuthPop('Open')
              }
            } else { // 정상처리가 아닌경우
              lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
            }
          })
          .catch(function (error) {
            window.vue.error(error)
          })
      }
    },

    /******************************************************************************
     * Function명 : fn_Validation
     * 설명       : 인증번호 버튼 클릭 시 유효성 체크
     ******************************************************************************/
    fn_Validation () {
      let lv_Msg = this.fn_CheckAgreePossible() // 만 14세 이상인지 여부 체크 - 14세 미만인 경우 confirm창의 메시지 내용이 리턴됨.

      if (lv_Msg != '') {
        this.getStore('confirm').dispatch('ADD', lv_Msg)
        return false
      }
      
      let lv_RetVal = true
      if (this.custCardVO.isExistCustYN === undefined || this.custCardVO.isExistCustYN === '') {
        this.getStore('confirm').dispatch('ADD', '고객 조회 후 진행가능합니다.')
        lv_RetVal = false
        return lv_RetVal
      }
      
      if (this.custCardVO.celnoFrno === '0') {
        this.getStore('confirm').dispatch('ADD', '휴대폰번호를 입력하세요.')
        lv_RetVal = false
        return lv_RetVal
      }
      
      if (this.infoPrcusCnsntTypCd === '19') {
        if (this.custCardVO.knbBkFstNo === '5' || this.custCardVO.knbBkFstNo === '6' || this.custCardVO.knbBkFstNo === '7' || this.custCardVO.knbBkFstNo === '8') {
          this.getStore('confirm').dispatch('ADD', '외국인 고객님은 마케팅동의 단독처리가 불가합니다.')
          lv_RetVal = false
          return lv_RetVal
        }
      } else {
        if (this.custCardVO.knbBkFstNo === '5' || this.custCardVO.knbBkFstNo === '6' || this.custCardVO.knbBkFstNo === '7' || this.custCardVO.knbBkFstNo === '8') {
          if (!this.isForeigner) {
            this.getStore('confirm').dispatch('ADD', '외국인 체크를 확인하시기 바랍니다.')
            lv_RetVal = false
            return lv_RetVal
          }
        } else {
          if (this.isForeigner) {
            this.getStore('confirm').dispatch('ADD', '외국인 체크를 해제하시기 바랍니다.')
            lv_RetVal = false
            return lv_RetVal
          }
        }
      }
      return lv_RetVal
    },

    /******************************************************************************
     * Function명 : fn_CheckCelno
     * 설명       : 핸드폰번호 유효성 체크
     ******************************************************************************/
    fn_CheckCelno () {
      this.chkCelnoResult = true
      let lv_Celno = this.custCardVO.celno.replace('-', '').replace('-', '').replace('-', '').trim()
      
      if (this.custCardVO.celnoFrno.key === '0') { // 선택일 경우 무조건 에러 처리
        this.chkCelnoResult = false
        this.celnoErrorMsg = this.$t('cm')['ECMC066'] // '휴대폰 번호를 정확히 입력하여 주세요'
      } else if (this.custCardVO.celnoFrno.key === '010') { // 010 번호인 경우 전체 자리수는 무조건 11자리
        if (lv_Celno.length < 11) {
          this.chkCelnoResult = false
          this.celnoErrorMsg = this.$t('cm')['ECMC066'] // '휴대폰 번호를 정확히 입력하여 주세요'
        } else if (lv_Celno.length === 11) {
          // 임시 휴대전화 등록 및 FC 번호와 동일한지 여부 체크
          this.fn_SelCelnoCheck()
        }
      } else {
        if (lv_Celno.length < 10) { // 010 외에는 뒷자리가 7자리(xxx-xxxx)로 올수도 있음. 전체 자리수를 10자리를 max로 해서 체크
          this.chkCelnoResult = false
          this.celnoErrorMsg = this.$t('cm')['ECMC066'] // '휴대폰 번호를 정확히 입력하여 주세요'
        } else {
          // 임시 휴대전화 등록 및 FC 번호와 동일한지 여부 체크
          this.fn_SelCelnoCheck()
        }
      }
    },

    /******************************************************************************
    * Function명 : fn_SelCelnoCheck
    * 설명       : 핸드폰번호 유효성 체크(임시 번호, FC와 동일번호 체크)
    ******************************************************************************/
    fn_SelCelnoCheck (paramCelno) {
      let lv_CustCelno = this.custCardVO.celno
      if (paramCelno !== undefined && paramCelno !== null && paramCelno !== '') { // created 시점의 임시번호 호출
        lv_CustCelno = paramCelno
      }
      if (lv_CustCelno === '01000000000' || lv_CustCelno === '0100000000' || lv_CustCelno === '01010000000' || lv_CustCelno === '01020000000' || lv_CustCelno === '01030000000') {
        this.chkCelnoResult = false
        this.celnoErrorMsg = this.$t('cm')['ECMC067'] // '임의번호는 입력할 수 없습니다.(예: 010-xxxx-0000, 010-xxxx-1111 등) 다시 확인 후 입력 바랍니다.'
        return
      }

      if ((paramCelno !== undefined && paramCelno !== null && paramCelno !== '') || (this.custCardVO.isCelnoChanged && this.custCardVO.unAprvCustYn === 'N')) { // 임시번호 호출이나 기존 승인고객의 핸드폰변경이 있는경우 유효성 체크
        this.isLoading = true
        let lv_Vm = this

        // 고객 VO복사
        let lv_CustCardVO = Object.assign({}, lv_Vm.custCardVO)

        if (this.$commonUtil.isMobile()) {
          if (lv_CustCardVO.knbBknoCipher.length < 8) { // 모바일이고, 주민번호 변경 안한 케이스
            if (lv_CustCardVO.knbBknoCipher !== '1000000' && lv_CustCardVO.knbBknoCipher !== '2000000' &&
              lv_CustCardVO.knbBknoCipher !== '3000000' && lv_CustCardVO.knbBknoCipher !== '4000000') { // 주민번호 확보 고객은 knb 제거
              lv_CustCardVO.knb = ''
            }
            lv_CustCardVO.knbBknoCipher = '' // 주민번호 미변경
          }
        }

        lv_CustCardVO.celno = lv_CustCelno

        let pParams = lv_CustCardVO
        let trnstId = 'txTSSCM10S3'

        this.post(lv_Vm, pParams, trnstId, 'S')
          .then(function (response) {
            if (response.body !== null) {
              if (response.body.celnoStatCo === '00') { // 정상일 경우
                lv_Vm.chkCelnoResult = true
              } else {
                if (response.body.celnoStatCo === '01') { // 01: 임시등록 번호 (*화면에서 1차로 filtering함. 현재는 사용안함.)
                  lv_Vm.chkCelnoResult = false
                  lv_Vm.celnoErrorMsg = lv_Vm.$t('cm')['ECMC067'] // '임의번호는 입력할 수 없습니다.(예: 010-xxxx-0000, 010-xxxx-1111 등) 다시 확인 후 입력 바랍니다.'
                } else if (response.body.celnoStatCo === '02') { // 02: FC휴대번호와 동일한 경우
                  lv_Vm.chkCelnoResult = false
                  lv_Vm.celnoErrorMsg = lv_Vm.$t('cm')['ECMC068'] // '컨설턴트 핸드폰번호로 등록된 번호입니다.'
                }
              }
            } else {
              lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
            }

            lv_Vm.isLoading = false
          })
          .catch(function (error) {
            window.vue.error(error)
          })
      }
    },

    /******************************************************************************
     * Function명 : fn_BtnP2OnClick
     * 설명       : 인증결과 확인 전송
     ******************************************************************************/
    fn_BtnP2OnClick () {
      this.isLoading = true
      // 인증결과값 초기화
      this.isSuccess = false
      this.resAthntStatRespCdVal = ''
      this.resAthntStatRespCdVal2 = ''

      let lv_Vm = this
      let pParams =
                    {
                      athntTrnstId: lv_Vm.athntTrnstId, // 인증트랜잭션ID
                      athntHstrId: lv_Vm.athntHstrId // 인증이력ID
                    }

      //매니저가 컨설턴트를 변경했을경우
      this.stndKeyList = []
      if(this.$cmUtil.fn_GachangeTF()){
        this.stndKeyList = [{stndKeyId: 'cnsltNo', stndKeyVal: this.getStore('cmStore').getters.getState.gssEplyInfo.eplyNo}]
      }

      let trnstId = 'txTSSCM12S3'
      this.post(lv_Vm, pParams, trnstId, 'S')
        .then(function (response) {
          //local 환경에서는 그냥 패스
          if( process.env.NODE_ENV === 'local' ){
            response.body = {}
            response.body.athntStatRespCd = 'P000'
            response.body.athntTrtStatCd = 'COMPLETE'
          }

          if (response.body !== null && response.body !== '') {
            if (response.body.athntStatRespCd === 'P000') {
              if (response.body.athntTrtStatCd === 'COMPLETE') {
                // 서명완료 - 인증전송 버튼 비활성화 / 인증결과 확인 버튼 비활성화
                lv_Vm.disabled_sendBtn = true
                lv_Vm.disabled_resBtn = true
                lv_Vm.isSuccess = true
                lv_Vm.custCardVO.athntHstrId = lv_Vm.athntHstrId
                // 인증 유형, 인증번호 저장을 위한 emit 호출 - MSPCM020M.vue의 fn_AuthCompleted 함수 호출
                // TODO
                lv_Vm.$emit('authCompleted', {infoPrcusCnsntPathCd: '16', aprvNo: '', custCardVO: lv_Vm.custCardVO})
              } else if (response.body.athntTrtStatCd === 'PREPARE') {
                // 대기중 - 인증전송 버튼 비활성화 / 인증결과 확인 버튼 활성화
                lv_Vm.disabled_sendBtn = true
                lv_Vm.disabled_resBtn = false
                lv_Vm.isSuccess = false
                lv_Vm.resAthntStatRespCdVal = '카카오 인증이 완료되지 않았습니다.'
                lv_Vm.resAthntStatRespCdVal2 = '인증 완료후 ‘인증결과 확인’을 다시 한 번 클릭하시기 바랍니다.'
              } else if (response.body.athntTrtStatCd === 'EXPIRED') {
                // 타임아웃 - 인증전송 버튼 활성화 / 인증결과 확인 버튼 비활성화
                lv_Vm.disabled_sendBtn = false
                lv_Vm.disabled_resBtn = true
                lv_Vm.isSuccess = false
                lv_Vm.resAthntStatRespCdVal = '인증시간이 초과되었습니다.'
                lv_Vm.resAthntStatRespCdVal2 = '‘전송’ 버튼을 눌러  재인증 하시기 바랍니다.'
              }
            } else {
              // 정상처리가 아닌경우 - 인증전송 버튼 활성화 / 인증결과 확인 버튼 비활성화
              lv_Vm.disabled_sendBtn = false
              lv_Vm.disabled_resBtn = true
              lv_Vm.isSuccess = false

              lv_Vm.resAthntStatRespCdVal = '카카오 인증이 실패되었습니다.'

              if (response.body.athntStatRespCd === 'E999') {
                lv_Vm.resAthntStatRespCdVal2 = '기타시스템오류'
              } else if (response.body.athntStatRespCd === 'E400') {
                lv_Vm.resAthntStatRespCdVal2 = '중복 호출. 잘못된 요청입니다.'
              } else if (response.body.athntStatRespCd === 'E401') {
                lv_Vm.resAthntStatRespCdVal2 = '조회 식별자가 존재하지 않습니다.'
              } else if (response.body.athntStatRespCd === 'E402') {
                lv_Vm.resAthntStatRespCdVal2 = '서명 결과가 존재하지 않습니다.'
              } else if (response.body.athntStatRespCd === 'E403') {
                lv_Vm.resAthntStatRespCdVal2 = '서명이 완료되지 않았습니다.'
              } else if (response.body.athntStatRespCd === 'E404') {
                lv_Vm.resAthntStatRespCdVal2 = 'Transaction ID 가 존재하지 않습니다.'
              } else if (response.body.athntStatRespCd === 'E405') {
                lv_Vm.resAthntStatRespCdVal2 = '이용기관 정보를 찾을 수 없습니다.'
              } else if (response.body.athntStatRespCd === 'E500') {
                lv_Vm.resAthntStatRespCdVal2 = '카카오 서버 에러'
              }
            }
          } else { // 정상처리가 아닌경우
            lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },
    /******************************************************************************
     * Function명 : fn_CheckCKnbGender
     * 설명       : 주민번호 뒷자리 성별구분코드에 따른 정합성 체크
     ******************************************************************************/
    fn_CheckCKnbGender () {
      let lv_Msg = '유효하지 않은 생년월일 정보입니다. 정확한 정보를 확인하시기 바랍니다.'
      // 주민번호 뒷자리 처리
      let lv_FrontYear = this.$bizUtil.addBirthFrontYear(this.custCardVO.knbFrno).substring(0, 2) // 19, 20 조회

      if (this.custCardVO.knbFrno !== null && this.custCardVO.knbFrno !== '') {
        if (lv_FrontYear === '19') {
          if (this.isForeigner === true) {
            if (this.custCardVO.knbBkFstNo !== '5' && this.custCardVO.knbBkFstNo !== '6') {
              if (this.custCardVO.knbBkFstNo === '1' || this.custCardVO.knbBkFstNo === '2') {
                this.chkKnbResult = false
                this.knbErrorMsg = '외국인 체크를 해제하세요'
              } else {
                this.chkKnbResult = false
                this.knbErrorMsg = lv_Msg
              }
            } else {
              this.chkKnbResult = true
            }
          } else {
            if (this.custCardVO.knbBkFstNo !== '1' && this.custCardVO.knbBkFstNo !== '2') {
              if (this.custCardVO.knbBkFstNo === '5' || this.custCardVO.knbBkFstNo === '6') {
                this.chkKnbResult = false
                this.knbErrorMsg = '외국인 체크를 하세요'
              } else {
                this.chkKnbResult = false
                this.knbErrorMsg = lv_Msg
              }
            } else {
              this.chkKnbResult = true
            }
          }
        } else if (lv_FrontYear === '20') {
          if (this.isForeigner === true) {
            if (this.custCardVO.knbBkFstNo !== '7' && this.custCardVO.knbBkFstNo !== '8') {
              if (this.custCardVO.knbBkFstNo === '5' || this.custCardVO.knbBkFstNo === '6') {
                this.chkKnbResult = false
                this.knbErrorMsg = '100세 이상의 보험가입은 지점에 연락해주시기 바랍니다.'
              } else {
                this.chkKnbResult = false
                this.knbErrorMsg = '외국인 체크를 해제하세요'
              }
            } else {
              this.chkKnbResult = true
            }
          } else {
            if (this.custCardVO.knbBkFstNo !== '3' && this.custCardVO.knbBkFstNo !== '4') {
              if (this.custCardVO.knbBkFstNo === '1' || this.custCardVO.knbBkFstNo === '2') {
                this.chkKnbResult = false
                this.knbErrorMsg = '100세 이상의 보험가입은 지점에 연락해주시기 바랍니다.'
              } else {
                this.chkKnbResult = false
                this.knbErrorMsg = '외국인 체크 하세요'
              }
            } else {
              this.chkKnbResult = true
            }
          }
        }
      }

      if (this.chkKnbResult) {
        lv_Msg = this.fn_CheckAgreePossible() // 만 14세 이상인지 여부 체크 - 14세 미만인 경우 confirm창의 메시지 내용이 리턴됨.

        if (lv_Msg != '') {
          this.chkKnbResult = false
          this.knbErrorMsg = lv_Msg
        }
      }

      return this.chkKnbResult
    },

    /******************************************************************************
     * Function명 : fn_CheckAgreePossible
     * 설명       : 만 14세 이상인지 여부 확인 후, 동의 여부 선택 가능한지 체크 - 필컨,마동 체크 선택이나 주민번호 입력 시 체크함.
     ******************************************************************************/
    fn_CheckAgreePossible () {
      let lv_Msg = ''
      if (this.infoPrcusCnsntTypCd) { // 필컨, 마동 둘중 하나라도 선택되어 있으면 14세 이상인지 체크
        if (this.custCardVO.knbFrno !== '' && this.custCardVO.knbFrno.length === 6 && this.custCardVO.knbBkFstNo !== '') {
          let lv_CheckAge = this.$bizUtil.compareCurrentAge(this.custCardVO.knbFrno, 14)

          if (lv_CheckAge === false) {
            lv_Msg = '해당 고객은 만 14세 미만으로 단독 동의진행이 불가합니다.'
            // this.getStore('confirm').dispatch('ADD', lv_Msg)
          }
        }
      }
      return lv_Msg
    },
    /******************************************************************************
     * Function명: fn_IsNextBtnVisible
     * 설명: 다음버튼 보이게 하는 여부 처리 (focus일 경우 false, blur(focus out)일 경우 true)
     * Params: N/A
     * Return: N/A
     ******************************************************************************/
    fn_IsNextBtnVisible (val, refNm) {
      if (this.$commonUtil.isMobile()) { // 모바일일 경우만 처리 - text input focus로 안드로이드 키보드 올라올 경우 '다음' 버튼 안보이게 처리
        this.isNextBtnVisible = val
        this.focusRefName = refNm
      }
    },
    /******************************************************************************
     * Function명: fn_Back
     * 설명: 헤더 영역 Back Button 파라미터 넘기기
     * Params: N/A
     * Return: N/A
     ******************************************************************************/
    fn_Back () {
      let pageCnt = window.vue.getStore('cmStore').getters.getState.viewCntCM050M
      window.vue.getStore('cmStore').dispatch('VIEW_CNT_CM050M', pageCnt-1)
      this.$router.go(-1)
      return
      // this.$commonUtil.setLastScreen(this.$options.screenId)
      // this.$router.push(
      //   {
      //     name: 'MSPCM004M',
      //     params: {
      //       name: 'MSPCM020M',
      //       agreeTypeMcnst: this.agreeTypeMcnst, //필컨 정보 여부
      //       mktChkList: this.mktChkList, //마컨 정보(광고성 수신동의)
      //       mktCnsntAvlPrd: this.mktCnsntAvlPrd, //마컨 정보(개인정보 보유이용기간)
      //       segmentType1: this.segmentType1, //마컨 정보(거래, 미거래)
      //       infoPrcusCnsntTypCd:  this.infoPrcusCnsntTypCd, //필컨만 : 17, 마동만 : 19, 필컨&마동 : 18
      //       custNm: this.routeCustNm
      //     }
      //   }
      // )
    },
    /******************************************************************************
    * Function명: _Back
    * 설명: 헤더 영역 Back Button 파라미터 넘기기
    *          >>>> 2차 개발하였으나, 사용안함
    * Params: N/A
    * Return: N/A
    ******************************************************************************/
    _Back () {
      return
      let alertMsg = '고객동의를 종료하시겠습니까?<br/> 동의종료 선택시 작성된 고객정보는 저장되지 않으며, 고객동의는 완료되지 않습니다.'
      let preSrnObj = window.vue.getStore('cmStore').getters.getState.preSrnObjCM050M
      let preSrnId = !this.$bizUtil.isEmpty(preSrnObj.srnId) ? preSrnObj.srnId : 'MSPBC002M'

      let alertObj = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          content: alertMsg,
          title_pos_btn: '나가기'
        },
        listeners: {
          onPopupConfirm: () => {
            this.$bottomModal.close(alertObj)
            
            window.vue.getStore('cmStore').dispatch('PRE_SRN_OBJ_CM050M', {}) // 진입전 정보 초기화
            this.$router.push({ name: preSrnId, params: preSrnObj.rtnParam })
          },
          onPopupClose: () => {
            this.$bottomModal.close(alertObj)
          }
        }
      })

    },
    /******************************************************************************
    * Function명 : fn_KakaoAuthPop
    * 설명       : 카카오 인증 실패 결과 안내 
    *           - OPEN(컨펌팝업열기), Confirm(창닫기) 선택에 대한 화면 이동
    ******************************************************************************/
    fn_KakaoAuthPop (param) {

      switch(param){
        case 'Open':
          this.$refs.kakaoAuthPop.open()
          break
        case 'Confirm':
          this.$refs.kakaoAuthPop.close()
          break
      }
      
    },
    /******************************************************************************
    * Function명 : fn_NextComp
    * 설명       : 입력 포커스 이동
    ******************************************************************************/
    fn_NextComp (param) {
      if (param === 'knbFrnoRef1' || param === 'knbFrnoRef2') { // 이름에서 focus out 되었을 때
        if (this.$bizUtil.isEmpty(this.custCardVO.custNm)) {
          return
        }
        this.$refs[param].focus()
      } else if (param === 'knbBknoRef') { // 주민번호 앞자리에서 focus out 되었을 때
        if (this.custCardVO.knbFrno.length < 6) {
          return
        }
        this.$refs[param].focus()
      } else if (param === 'celnoRef') { // 통신사 선택 후
        this.$refs[param].focus()
      }

      // this.$refs[param].focus()
    }
  }//methods end
}
</script>